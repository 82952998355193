import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleNotch } from '@fortawesome/pro-duotone-svg-icons'
import parseISO from 'date-fns/parseISO'
import format from 'date-fns/format'

const Conversation = ({conversation, hideComments}) => {
  return (
    <>
      {!conversation.loading &&
        <div className="sar-messages">
         {conversation.messages && conversation.messages
                                              .filter(m => m.part_type != 'assignment' && m.body)
                                              .filter(m => !(m.part_type == 'note' && hideComments))
                                              .map((msg, key) =>
          <div className={`card ${msg.part_type} mb-3 intercom-${msg.sent_by}`} key={key}>
            <div className="card-header">
              {msg.part_type === 'note' ? 'Internal Note' : 'Sent'} by&nbsp;
              {msg.author} ({msg.sent_by}) at {format(parseISO(msg.sent_at),'PPpp')}
              {msg.subject &&
                <>
                  <br />Subject: {msg.subject}
                </>
              }
            </div>
            {msg.body &&
              <div
                className="card-body"
                dangerouslySetInnerHTML={{ __html: msg.body }}
              >
              </div>
            }
          </div>
        )}
      </div>
      }
      {conversation.loading &&
        <div className="d-flex align-items-center p-3">
          <span>Conversation {conversation.id} loading (refresh to check progress)</span>
          <FontAwesomeIcon className="ms-auto" icon={faCircleNotch} spin />
        </div>
      }
    </>
  )
}

export default Conversation
