import React, { useState }from 'react'
import { useStoreState, useStoreActions } from 'easy-peasy'
import ReplyText from './reply-text'
import Recipients from './recipients'
import InsertConversation from './insert-conversation'
import Form from './form'
import { format, parseISO } from 'date-fns'
import '../css/forwarded.scss'

const FwdReply = (props) => {
  // use state
  const conversation = useStoreState(state => state.conversation)
  const subject = useStoreState(state => state.subject)
  const setSubject = useStoreActions(actions => actions.setSubject)
  const insertedMessages = useStoreState(state => state.insertedMessages)

  const dateTimeFormat = 'PPpp'

  const updateSubject = (event) => {
    const el = event.target
    setSubject(el.value)
  }

  return (
    <div className="card mt-3">
      <div className="card-header">
        Subject
      </div>
      <div className="card-body form-group">
        <input
          type="text"
          className="form-control"
          placeholder="Type subject for email here"
          onChange={updateSubject}
          value={subject}
        />
      </div>
      <Recipients
        titleText="Send message to:"
        customContact={true}
      />
      <div className="card-header border-top">
        Message
      </div>
      <div className="card-body mt-0">
        <ReplyText
          placeholder="Type a message here"
        />
        <InsertConversation />
        {insertedMessages.length > 0 &&
          <div className="inserted-messages">
           <div className="header">
              conversation with {conversation.people} updated: {format(parseISO(conversation.last_updated_at), dateTimeFormat)}
            </div>
            {insertedMessages.map((message, key) =>
              <div key={key} className={`inserted-message d-flex flex-column ${message.sent_by}`}>
                <div className={`message-header d-flex align-items-center ps-2 pe-2 pt-2 ${!message.subject && 'pb-2'}`}>
                  <div className="source">From: {message.author}</div>
                  <div className="sent-at ms-auto">{format(parseISO(message.sent_at), dateTimeFormat)}</div>
                </div>
                {message.subject &&
                  <div className="message-subject ps-2 pe-2 pb-2">Subject: {message.subject}</div>
                }
                <div
                  className="message-body"
                  dangerouslySetInnerHTML={{__html: message.body}}
                >
                </div>
                
              </div>
            )}
          </div>
        }
      </div>
      <Form
        buttonText="Send Message"
      />
    </div>
    )
}

export default FwdReply
