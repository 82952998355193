import React from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleNotch, faCheckCircle } from '@fortawesome/pro-duotone-svg-icons'

const ItemStatus = (props) => {
  const task = props.task

  return (
    <div className="task-status d-flex align-items-center list-group-item">
      {task.complete &&
        <FontAwesomeIcon
          className="me-2 text-success"
          icon={faCheckCircle}
          size="lg"
        />
      }
      {!task.complete &&
          <FontAwesomeIcon
            className="me-2 text-muted"
            icon={faCircleNotch}
            size="lg"
            spin
          />
      }
      {task.status}
      {task.link &&
        <a className="ms-2 badge badge-success" target="_blank" href={task.link}>view</a>
      }
    </div>
  )
}

ItemStatus.propTypes = {
  task: PropTypes.object
}

export default ItemStatus
