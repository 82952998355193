import React from 'react'
import { useStoreState, useStoreActions } from 'easy-peasy'

const Menu = (props) => {
  const copyType = useStoreState(state => state.copy_type)
  const setCopyType = useStoreActions(actions => actions.setCopyType)

  const selectMenu = (event) => {
    setCopyType(event.target.getAttribute('data-value'))
  }

  return (
    <div className="nav nav-pills nav-fill">
      <div className="nav-item">
        <a
          href="#"
          data-value="bcc"
          onClick={selectMenu}
          className={`nav-link ${copyType === 'bcc' && 'active'}`}>
          Reply with BCC
        </a>
      </div>
      <div className="nav-item">
        <a
          href="#"
          data-value="fwd"
          onClick={selectMenu}
          className={`nav-link ${copyType === 'fwd' && 'active'}`}>
          Forward
        </a>
      </div>
    </div>
  )
}

export default Menu
