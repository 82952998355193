import React from 'react'
import { useStoreState, useStoreActions } from 'easy-peasy'

const Search = () => {
  return (
    <>
      Search
    </>
  )
}

export default Search
