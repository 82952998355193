import React from 'react'
import { useStoreState, useStoreActions } from 'easy-peasy'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleNotch } from '@fortawesome/pro-duotone-svg-icons'
import Conversation from './conversation'
import parseISO from 'date-fns/parseISO'
import format from 'date-fns/format'

const ConversationList = () => {
  const conversations = useStoreState(state => state.orderedConversations)
  const loading = useStoreState(state => state.loading)
  const hideComments = useStoreState(state => state.hideComments)
  const toggleComments = useStoreActions(actions => actions.toggleComments)
  const csvData = useStoreState(state => state.csvData)
  const contact = useStoreState(state => state.subject)

  return (
    <>
      {!loading &&
        <div className="d-flex align-items-center mb-3 d-print-none">
          <div className="me-3 btn btn-sm btn-secondary" onClick={toggleComments}>
            {hideComments ? 'Show Notes' : 'Hide Notes'}
          </div>
          {hideComments ? 'Notes are hidden' : 'All notes shown'}
          <a href={csvData} download={`${contact.name}.csv`} className="ms-auto btn btn-sm btn-info">
            Download as CSV
          </a>
        </div>
      }
      {!loading && conversations.map((conversation, key) =>
        <div className="card mb-3">
          <div className="card-header bg-secondary text-white">
            Conversation started {format(parseISO(conversation.started_at),'PPpp')} by {conversation.source}
          </div>
          <div className="card-body">
             <Conversation
              conversation={conversation}
              hideComments={hideComments}
              key={key}
            />
          </div>
        </div>
      )}
      {loading &&
        <div className="d-flex align-items-center">
          <span>Loading conversations from intercom (refresh to update)</span>
          <FontAwesomeIcon className="ms-auto" icon={faCircleNotch} spin />
        </div>
      }
    </>
  )
}

export default ConversationList