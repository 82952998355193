import React, { useRef } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch, faExclamationTriangle, faCircleNotch, faTimes } from '@fortawesome/pro-duotone-svg-icons'
import { useDebouncedCallback } from 'use-debounce'
import styles from './index.scss'
import { useStoreState, useStoreActions } from 'easy-peasy'
import unescape from 'lodash.unescape'

const Search = ({ placeholder, delay, editUrl }) => {
  const results = useStoreState(state => state.results)
  const baseUrl = useStoreState(state => state.baseUrl)
  const error = useStoreState(state => state.error)
  const searchQuery = useStoreActions(actions => actions.search)
  const submitting = useStoreState(state => state.submitting)
  const searchString = useStoreState(state => state.searchString)

  const resultsDropdown = useRef(null)

  const hideSearchResults = () => {
    resultsDropdown.current.style.display = 'none'
  }

  const debounced = useDebouncedCallback(
    // function
    (query) => {
      searchQuery(query)
    },
    // delay in ms
    delay || 500
  )

  return (
    <div className={`input-group wrapper`}>
      <span className="input-group-text">
        <FontAwesomeIcon icon={faSearch} />
      </span>
      <input
        type="text"
        className="form-control"
        placeholder={placeholder || 'Search for specific item'}
        onChange={(e) => debounced(e.target.value)}
      />
      {error &&
        <span className="input-group-text">
          <FontAwesomeIcon icon={faExclamationTriangle} className="text-danger" />
        </span>
      }
      {submitting &&
        <span className="input-group-text">
          <FontAwesomeIcon icon={faCircleNotch} spin={true} />
        </span>
      }
      {searchString.length > 0 && !submitting &&
        <ul ref={resultsDropdown} className="search">
          {results.map((res, key) =>
            <li key={key}>
              <a className="dropdown-item d-flex align-items-center" href={`${editUrl || baseUrl}/${res.id}`}>
                <span
                  className="description"
                  dangerouslySetInnerHTML={{
                    __html: res.description
                  }}>
                </span>
                <span className="secondary ms-auto">
                  {res.secondary_text}
                </span>
              </a>
            </li>
          )}
          {results.length === 0 &&
            <>
              <li>
                <a className="dropdown-item btn d-flex align-items-center" onClick={hideSearchResults}>
                  There are no results for '{searchString}'
                  <span className="btn ms-auto"><FontAwesomeIcon icon={faTimes} className="" onClick={hideSearchResults} /></span>
                </a>
              </li>
            </>
          }
        </ul>
      }
    </div>
  )
}

export default Search