import React from 'react'
import PropTypes from 'prop-types'
import { useStoreState, useStoreActions } from 'easy-peasy'
import AddRecipient from './add-recipient'
import TherapistList from './therapist-list'

const Recipients = (props) => {
  // local text
  const defaultText = props.defaultText || 'No recipients selected'
  const titleText = props.titleText || 'Blind copy reply to:'
  const customContact = props.customContact
  const customContactText = props.customContactText || 'Enter email address manually'

  // global state
  const recipients = useStoreState(state => state.recipients)
  const removeRecipient = useStoreActions(actions => actions.removeRecipient)
  const conversation = useStoreState(state => state.conversation)
  const addRecipientVisible = useStoreState(state => state.addRecipientVisible)
  const setAddRecipientVisible = useStoreActions(action => action.setAddRecipientVisible)

  const removeAssignedRecipient = (event) => {
    const el = event.target
    removeRecipient(el.getAttribute('data-index'))
  }

  const Recipient = (props) => {
    const index = props.index
    const recipient = props.recipient

    return (
      <div className="list-group-item d-flex align-items-center">
        <div>{recipient.name} ({recipient.email})</div>
        <a data-index={index} className="btn ms-auto badge badge-danger" onClick={removeAssignedRecipient}>&times; Remove</a>
      </div>
    )
  }

  const addNewRecipient = () => {
    setAddRecipientVisible(false)
  }

  const toggleAddRecipient = () => {
    setAddRecipientVisible(!addRecipientVisible)
  }

  return (
    <>
      <div className={`card-header d-flex align-items-center justify-content-between border-top ${addRecipientVisible ? 'border-bottom-0' : ''}`}>
        <span>{titleText}</span>
        <TherapistList />
      </div>
     {addRecipientVisible &&
        <div className="card-header">
          <AddRecipient />
        </div>
      }
      {recipients.length > 0 &&
        <div className="list-group-flush">
          {recipients.map((recipient, key) =>
            <Recipient
              recipient={recipient}
              key={key}
              index={key}
            />
          )}
        </div>
      }
      {recipients.length === 0 &&
        <div className="card-body">{defaultText}</div>
      }
    </>
  )
}

Recipients.propTypes = {
  titleText: PropTypes.string,
  defaultText: PropTypes.string,
  customContact: PropTypes.bool,
  customContactText: PropTypes.string
}

export default Recipients
