import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useStoreState, useStoreActions } from 'easy-peasy'
import ActionCable from 'actioncable'

import ItemStatus from './item-status'

const Status = (props) => {
  const reply = useStoreState(state => state.reply)
  const updateReply = useStoreActions(actions => actions.updateReply)

  const cable = ActionCable.createConsumer("wss://intercom.harleytherapy.co.uk/cable")

  const channelHandlers = {
    received(data) {
      if (data.reply) {
        updateReply(data.reply)
      }
    }
  }

  const channelParams = {
    channel: 'ConversationReplyChannel',
    conversation_reply: reply.id
  }

  // second param [] is to run this only once
  useEffect(() => {
    const channel = cable.subscriptions.create(channelParams, channelHandlers)

    return(() => channel.unsubscribe())
  }, [])

  return (
    <div className="list-group list-group-flush">
      {reply.tasks.map((task, key) => 
        <ItemStatus
          task={task}
          key={key}
        />
      )}
    </div>
  )
}

export default Status
