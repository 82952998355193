import React from 'react'
import useModel from './model'
import Therapist from './therapist'

const TherapistList = () => {
  const model = useModel()

  return (
    <div>
      {model.therapists && model.therapists.length === 0 ? (
        <div>There are no therapists in the system</div>
      ) : (
        <div className="list-group mt-3">
          {model.sortedTherapists.map((therapist) => (
            <Therapist key={therapist.id} therapist={therapist} />
          ))}
        </div>
      )}
    </div>
  )
}

export default TherapistList
