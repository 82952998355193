import React from 'react'
import TherapistForm from './form'
import useModel from './model'
import intercomLogo from './intercom-logo.png'

const Therapist = ({ therapist }) => {
  const model = useModel()

  const editing = model.editingTherapistId === therapist.id

  return (
    <div className={`list-group-item p-0 d-flex flex-column`}>
      <div
        className={`d-flex align-items-center p-2 ${editing ? 'bg-light' : ''}`}
      >
        <div>{therapist.name}</div>
        {therapist.intercom_id && (
          <a
            href={
              'https://app.intercom.com/a/apps/qnjnps8f/users/' +
              `${therapist.intercom_id}/all-conversations`
            }
            target="_blank"
            className="ms-1"
            title={`View ${therapist.name} in intercom...`}
          >
            <img
              src={intercomLogo}
              style={{ width: '20px', height: '20px', display: 'block' }}
            />
          </a>
        )}
        <button
          className={`ms-auto btn btn-sm btn-outline-secondary ${
            editing ? 'disabled' : ''
          }`}
          onClick={() => (model.editingTherapistId = therapist.id)}
          disabled={editing}
        >
          Edit
        </button>
      </div>
      {editing && <TherapistForm therapist={therapist} />}
    </div>
  )
}

export default Therapist
