import React, { useState } from 'react'
import { useStoreState, useStoreActions } from 'easy-peasy'
import Select from 'react-select'
import truncate from 'lodash/truncate'
import en from 'javascript-time-ago/locale/en'
import TimeAgo from 'javascript-time-ago'
import { format, parseISO } from 'date-fns'

// Add locale-specific relative date/time formatting rules.
TimeAgo.addLocale(en)
const timeAgo = new TimeAgo('en-GB')
const dateTimeFormat = 'PPpp'

const InsertConversation = (props) => {
  const userMessages = useStoreState(state => state.userMessages)
  const insertMessages = useStoreActions(actions => actions. insertMessages)
  const messages = useStoreState(state => state.messages)

  // local state
  const [selectedValue, setSelectedValue] = useState('none')

  const stripTags = (html) => {
    const doc = new DOMParser().parseFromString(html, 'text/html')
    return doc.body.textContent || ''
  }

  const firstMessage = () => {
    return userMessages[0] || {}
  }

  const lastMessage = () => {
    return userMessages[userMessages.length - 1] || {}
  }

  const options = () => {
    return [
      {
        label: `Don't include any messages`,
        value: 'none'
      },
      {
        label: `Insert first message from ${lastMessage().author} (${truncate(stripTags(lastMessage().body), 20)})`,
        value: 'first_message'
      },
      {
        label: `Insert only last message from ${firstMessage().author} (${truncate(stripTags(firstMessage().body), 20)})`,
        value: 'last_message'
      },
      {
        label: 'Insert full conversation (excluding notes)',
        value: 'conversation'
      },
    ]
  }

  const selectedOption = () => {
    options().filter(o => o.value === selectedValue)
  }

  const allMessages = () => {
    return messages.filter(message => message.part_type !== 'note' && message.body)
  }

  const addToConversation = (item) => {
    setSelectedValue(item.value)
    if (item.value === 'last_message') {
      insertMessages([firstMessage()])
    } else {
      if (item.value === 'first_message') {
        insertMessages([lastMessage()])
      } else {
        if (item.value === 'conversation') {
          insertMessages(allMessages())
        } else {
          insertMessages([])
        }
      }
      
    }
  }

  return (
    <div className="mt-3">
      {userMessages.length > 0 &&
        <Select
          options={options()}
          value={selectedOption()}
          isClearable={true}
          placeholder="Insert conversation into message..."
          onChange={addToConversation}
        />
      }
    </div>
  )
}

export default InsertConversation