import React, { useState } from 'react'
import { useStoreState, useStoreActions } from 'easy-peasy'
import EmailValidator from 'email-validator'
import { useDebouncedCallback } from 'use-debounce'
import HarleyFetch from '../../lib/fetch'
import styles from '../css/search.module.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleNotch, faSearch } from '@fortawesome/pro-duotone-svg-icons'


const AddRecipient = (props) => {
  const [customContactValid, setCustomContactValid] = useState(false)

  const [recipientName, setRecipientName] = useState('')
  const [recipientEmail, setRecipientEmail] = useState('')

  const [results,setResults] = useState([])
  const [showResults,setShowResults] = useState(false)
  const [searching,setSearching] = useState(false)

  // search
  const search = (query) => {
    setSearching(true)
    HarleyFetch(`/search?query=${query}`)
      .then(response => {
        setSearching(false)
        if (response.ok) {
          response.json()
            .then(data => {
              setResults(data)
              setShowResults(true)
            })
        } else {
          alert('Error searching')
        }
      })
  }

  // global state
  const addRecipientVisible = useStoreState(state => state.addRecipientVisible)
  const setAddRecipientVisible = useStoreActions(action => action.setAddRecipientVisible)
  const addRecipient = useStoreActions(actions => actions.addRecipient)
  const conversation = useStoreState(state => state.conversation)

  // search
  const debounced = useDebouncedCallback(
    // function
    (query) => {
      search(query)
    },
    // delay in ms
    500
  )

  const customRecipient = () => {
    const email = document.getElementById('custom_email').value.toLowerCase()
    const name = document.getElementById('custom_name').value

    setRecipientName(name)
    setRecipientEmail(email)

    if (!EmailValidator.validate(email)) {
      return false
    } else {
      if (name.length < 3) {
        return false
      } else {
        return {
          name: name,
          email: email,
          copiable_type: 'IntercomContact'
        }
      }
    }
  }

  const addCustomRecipient = () => {
    addRecipient(customRecipient())
    setAddRecipientVisible(false)
  }

  const updateCustomContact = () => {
    setCustomContactValid(customRecipient())
  }

  const cancel = () => {
    setAddRecipientVisible(false)
  }

  const addSearchResultAsRecipient = (result) => {
    addRecipient(result)
    setAddRecipientVisible(false)
  }

  return (
    <>
      {addRecipientVisible &&
        <>
          <div className="mb-3">
            <div className="input-group">
              <span className="input-group-text" id="basic-addon1">Search</span>
              <input
                type="text"
                className="form-control"
                placeholder="Search for previously created contact "
                onChange={(e) => debounced(e.target.value)}
              />
              <span className="input-group-text">
                {searching &&
                  <FontAwesomeIcon icon={faCircleNotch} spin />
                }
                {!searching &&
                  <FontAwesomeIcon icon={faSearch} />
                }
              </span>
            </div>
            {showResults &&
              <div className={`list-group ${StyleSheet.results}`}>
                {results.map((result, key) =>
                  <div 
                    className="list-group-item list-group-item-action d-flex align-items-center"
                    key ={key}
                    onClick={() => addSearchResultAsRecipient(result)}
                  >
                    <div>{result.name}</div>
                    <div
                      className={`ms-auto ${styles.highlight}`}
                      dangerouslySetInnerHTML={ { __html: result.description }}
                    >
                    </div>
                  </div>
                )}
              </div>
            }
          </div>
          <div className="form-group pt-0 mb-0 pb-0 d-flex flex-column">
            <label className="mb-0">Name</label>
            <input
              type="text"
              className="form-control"
              id="custom_name"
              placeholder="Type name of contact here"
              onChange={updateCustomContact}
            />
            <label className="mb-0">Email</label>
            <input
              type="email"
              className="form-control"
              id="custom_email"
              placeholder="Type email adress here"
              onChange={updateCustomContact}
              value={recipientEmail}
            />
          </div>
          <div className="d-flex mt-2">
            <button className="btn btn-sm btn-outline-secondary" onClick={cancel}>
              Cancel
            </button>
            <button disabled={!customContactValid} className="ms-auto btn btn-sm btn-primary"  onClick={addCustomRecipient}>
              Add {recipientName}
            </button>
          </div>
        </>
      }
    </>
  )
}

export default AddRecipient
