import React from 'react'
import { useStoreState, useStoreActions } from 'easy-peasy'
import PropTypes from 'prop-types'

const ReplyForm = (props) => {
  // props
  const buttonText = props.buttonText || 'Send Reply'
  // state & actions
  const conversation = useStoreState(state => state.conversation)
  const recipients = useStoreState(state => state.recipients)
  const copyType = useStoreState(state => state.copy_type)
  const fromAdminId = useStoreState(state => state.admin_id)
  const reply = useStoreState(state => state.reply)
  const subject = useStoreState(state => state.subject)
  const submitActive = useStoreState(state => state.submitActive)
  const updateFromEmail = useStoreActions(actions => actions.updateFromEmail)
  const insertedMessages = useStoreState(state => state.insertedMessages)

  const Therapist = (props) => {
    const item = props.item
    const index = props.index
    return(
      <>
        <input
          type="hidden"
          name={`conversation_reply[recipients_attributes][${index}][copiable_id]`}
          value={item.copiable_id}
        />
        <input
          type="hidden"
          name={`conversation_reply[recipients_attributes][${index}][copiable_type]`}
          value={item.copiable_type}
        />
      </>
    )
  }

  const TherapistRecipients = () => {
    const therapists = recipients.filter(item => item.copiable_type === 'Therapist' )
    return(
      <>
        {therapists.map((item, key) =>
          <Therapist key={key} item={item} index={key} />
        )}
      </>
    )
  }

  const CustomRecipients = () => {
    return(
      <input
        name="conversation_reply[custom_recipients]"
        type="hidden"
        value={JSON.stringify(recipients.filter(item => item.copiable_type !== 'Therapist' ))}
      />
    )
  }

  const InsertedMessages = () => {
    return (
      <input
        name="conversation_reply[selected_messages]"
        type="hidden"
        value={JSON.stringify(insertedMessages)}
      />
    )
  }

  const csrfToken = () => {
    if (typeof document !== 'undefined') {
      const token = document.getElementsByName('csrf-token')[0]
      return token.getAttribute('content')
    }
  }

  const updateFrom = (event) => {
    updateFromEmail(event.target.value)
  }

  return (
    <form action="/conversation_replies" method="post">
        <div className="card-header border-top">
          Send from:
        </div>
        <div className="card-body d-flex align-items-center">
          <select name="conversation_reply[sent_by]" className="form-select" defaultValue={fromAdminId} onChange={updateFrom}>
            {conversation.admins.map((admin, key) =>
              <option key={key} value={admin.id}>
                {admin.name} ({admin.email})
              </option>
            )}
          </select>
        </div>
        <div className="card-footer d-flex align-items-center">
          <input type="hidden" name="authenticity_token" value={csrfToken()} />
          <input type="hidden" name="conversation_reply[intercom_conversation_id]" value={conversation.conversation_id} />
          <input type="hidden" name="conversation_reply[message]" value={reply || ''} />
          <input type="hidden" name="conversation_reply[subject]" value={subject} />
          <input type="hidden" name="conversation_reply[copy_type]" value={copyType} />
          <TherapistRecipients />
          <CustomRecipients />
          <InsertedMessages />
          <button type="submit" className={'btn btn-primary ms-auto' + (submitActive ? '' :  ' disabled')}>
            {buttonText} &raquo;
          </button>
        </div>
      </form>
  )
}

ReplyForm.propTypes = {
  buttonText: PropTypes.string
}

export default ReplyForm
