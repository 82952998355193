import React from 'react'
import { useStoreState } from 'easy-peasy'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes, faCheckCircle } from '@fortawesome/pro-duotone-svg-icons'

import Status from './status'

const closeWindow = () => {
  window.close()
}

const Container = (props) => {
  const reply = useStoreState(state => state.reply)
  return (
    <div className="card">
      <div className="card-header">
        {reply.description}
        {reply.copy_type == 'bcc' && reply.copiable &&
          <div className="d-flex align-items-center">
            <span className="badge badge-pill badge-info me-1">bcc'd to:</span>
            <span className="bcc">to {reply.copiable.name} ({reply.copiable.email})</span>
          </div>
        }
        {reply.conversation_link &&
          <a className="ms-2 badge badge-info" target="_blank" href={reply.conversation_link}>view conversation</a>
        }
      </div>
      <Status />
      <div className="card-footer d-flex justify-content-center">
        <div className="btn btn-secondary" onClick={closeWindow}>
          <FontAwesomeIcon
            className="me-2"
            icon={faTimes}
          />
          close tab
          {!reply.complete &&
            <span className="ms-2">(will not stop messages being sent)</span>
          }
        </div>
      </div>
    </div>
  )
}

export default Container
