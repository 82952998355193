import { createStore, action, computed, thunk } from 'easy-peasy'
import harleyFetch from '../fetch'

const searchModel = {
  baseUrl: '',
  results: [],
  submitting: false,
  error: false,
  searchString: '',
  url: computed((state) => {
    return `${state.baseUrl}/search?query=${state.searchString}`
  }),
  setError: action((state, value) => {
    state.error = value
  }),
  toggleSubmitting: action((state) => {
    state.submitting = !state.submitting
  }),
  setSearchString: action((state, search) => {
    state.searchString = search
  }),
  replaceResults: action((state, newResults) => {
    state.results = [...newResults]
  }),
  search: thunk((actions, search, { getState }) => {
    actions.setError(false)
    actions.toggleSubmitting()
    actions.setSearchString(search)
    harleyFetch(getState().url)
      .then((response) => {
        actions.toggleSubmitting()
        if (response.ok) {
          response.json().then((data) => {
            actions.replaceResults(data)
          })
        } else {
          actions.setError(true)
        }
      })
  })
}

const store = (initialState) => {
  return createStore(searchModel, {
    initialState: initialState,
    name: 'SearchStore'
  })
}

export default store
