import { createStore, action, computed } from 'easy-peasy'

const store = (initialState) => {
  return createStore(
    {
      conversations: [],
      loading: true,
      hideComments: false,
      subject: {},
      replaceConversations: action((state, newConversations) => {
        state.conversations = [...newConversations]
      }),
      toggleComments: action((state) => {
        state.hideComments = !state.hideComments
      }),
      orderedConversations: computed((state) => {
        return state.conversations.sort((a, b) => {
          var dateA = new Date(a.started_at);
          var dateB = new Date(b.started_at);
          return dateA - dateB;
        })
      }),
      csvData: computed((state) => {
        let lines = []
        lines.push(`Conversations recorded with ${state.subject.name || state.subject.email || `Unknown:${state.subject.type}:${state.subject.id}`}`)
        lines.push('\n')
        state.orderedConversations.forEach((conversation) => {
          lines.push('---------------------------------------------------------------------')
          lines.push(`Conversation starting at ${conversation.started_at}`)
          lines.push('\n')
          lines.push('Date,From,Type,Subject,Body')
          conversation.messages
                      .filter(m => m.part_type != 'assignment' && m.body)
                      .filter(m => !(m.part_type == 'note' && state.hideComments))
                      .forEach((message) => {
            lines.push(`${message.sent_at},${message.author} (${message.sent_by}),${message.part_type},${message.subject},"${message.body.replace(/"/g, '""').replace('\n','')}"`)
          })
        })

        return URL.createObjectURL(new Blob([lines.join('\n')], { type: 'text/plain' }))
      })
    },
    { initialState: initialState }
  )
}

export default store


