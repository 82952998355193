import React from 'react'
import { StoreProvider } from 'easy-peasy'
import PropTypes from 'prop-types'
import store from './store'

import ExportList from './components/export-list'

const Exports = (props) => {
  const initialState = {
    exports: props.exports,
    baseUrl: props.baseUrl || ''
  }

  return (
    <StoreProvider store={store(initialState)}>
      <ExportList />
    </StoreProvider>
  )

}

Exports.propTypes = {
  exports: PropTypes.array,
  baseUrl: PropTypes.string
}

export default Exports