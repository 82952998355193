import React, { useState }from 'react'
import { useStoreState, useStoreActions } from 'easy-peasy'
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap'
import ReplyText from './reply-text'
import Form from './form'
import Recipients from './recipients'

const BccReply = (props) => {
  // local state
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const toggle = () => setDropdownOpen(prevState => !prevState)

  // component state
  const conversation = useStoreState(state => state.conversation)
  const fromAdminId = useStoreState(state => state.admin_id)

  const updateEmail = (event) => {
    const el = event.target
    setBccEmail({
      name: el.getAttribute('data-name'),
      email: el.getAttribute('data-email'),
      id: el.getAttribute('data-id'),
      type: el.getAttribute('data-type')
    })
  }

  return (
    <div className="card mt-3">
     <div className="card-header">
        Reply to: {conversation.people}
      </div>
      <div className="card-body mt-0">
        <div className="card-title">Reply</div>
        <ReplyText />
      </div>
      <Recipients />
      <Form />
    </div>
    )
}

export default BccReply
