import React, { useState } from 'react'
import { useStoreState, useStoreActions } from 'easy-peasy'
import { ReactTrixRTEInput } from 'react-trix-rte'
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap'
import BccReply from './bcc-reply'
import FwdReply from './fwd-reply'
import ReplyForm from './form'
import Menu from './menu'
import { format, parseISO } from 'date-fns'

const dateTimeFormat = 'PPpp'

const ReplyMessage = props => {
  const copy_type = useStoreState(state => state.copy_type)
  const open = useStoreState(state => state.open)
  const [ok, setOk] = useState(false)
  const hasOpenReplies = (open.length > 0 && !ok)

  return (
    <>
      {hasOpenReplies &&
        <div className="card">
          <div className="card-header">
            There are unsent messages for this conversation:
          </div>
          <div className="list-group list-group-flush">
            {open.map((convo, key) =>
              <div className="list-group-item d-flex align-items-center" key={key}>
                <span>
                  {convo.subject} created by {convo.created_by.name} on {format(parseISO(convo.created_at), dateTimeFormat)}
                </span>
                <a
                  className="btn btn-outline-secondary btn-sm ms-auto"
                  href={`/conversation_replies/${convo.id}`}
                  target="_blank">
                  View Status
                </a>`
              </div>
            )}
          </div>
          <div className="card-footer">
            <button
              className="btn btn-primary"
              onClick={() => setOk(true)}
              >
              Continue to create a new reply
            </button>
          </div>
        </div>

      }
      {!hasOpenReplies &&
        <>
          <Menu />
          {copy_type === 'bcc' &&
            <BccReply />
          }
          {copy_type === 'fwd' &&
            <FwdReply />
          }
        </>
      }
    </>
  )
}

export default ReplyMessage