import React from 'react'
import { useStoreState } from 'easy-peasy'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleNotch } from '@fortawesome/pro-duotone-svg-icons'
import parseISO from 'date-fns/parseISO'
import format from 'date-fns/format'

const ExportList = () => {
  const exports = useStoreState(state => state.exports)
  const baseUrl = useStoreState(state => state.baseUrl)

  return (
    <div className="list-group list-group-flush">
      {exports.map((ex, key) =>
        <div className="list-group-item d-flex align-items-center" key={key}>
          {format(parseISO(ex.created_at), 'PPpp')}
          {ex.loading_status &&
            <FontAwesomeIcon className="ms-auto" icon={faCircleNotch} spin />
          }
          {!ex.loading_status &&
            <a className="btn btn-primary btn-sm ms-auto" href={`${baseUrl}/intercom_exports/${ex.id}`}>
              View
            </a>
          }
        </div>
      )}
    </div>
  )
}

export default ExportList