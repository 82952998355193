import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useStoreState, useStoreActions } from 'easy-peasy'
import Select from 'react-select'


const TherapistList = (props) => {
  // global state
  const conversation = useStoreState(state => state.conversation)
  const addRecipientVisible = useStoreState(state => state.addRecipientVisible)
  const setAddRecipientVisible = useStoreActions(action => action.setAddRecipientVisible)
  const addRecipient = useStoreActions(action => action.addRecipient)

  // local state
  const [selectedValue, setSelectedValue] = useState(null)

  //props
  const allowCustom = typeof(props.allowCustom) === 'undefined' ? true : props.allowCustom

  const customStyles = {
    container: (provided) => ({
      ...provided,
      width: '35%'
    })
  }

  const selectOptions = () => {
    let options = []

    if (allowCustom) {
      options.push({
        label: 'Search enter email manually',
        value: 'manual'
      })
    }

    if (conversation.own_therapists.length > 0) {
      options.push(
        {
          label: 'Related Therapists',
          options: conversation.own_therapists.map(therapist => ({ label: therapist.name, value: therapist.id, email: therapist.email }))
        }
      )
    }
    options.push({
      label: `${conversation.own_therapists.length == 0 ? 'All' : 'Other' } therapists`,
      options: conversation.other_therapists.map(therapist => ({ label: therapist.name, value: therapist.id, email: therapist.email }))
    })
    return options
  }

  const selectAction = (option) => {
    if (option !== null && option.value == 'manual') {
      setAddRecipientVisible(true)
    } else {
      if (option === null) {
        setAddRecipientVisible(false)
      } else {
        if (addRecipientVisible) {
          setAddRecipientVisible(false)
        }
        addRecipient({
          name: option.label,
          copiable_id: option.value,
          email: option.email,
          copiable_type: 'Therapist'
        })
      }
      setSelectedValue(null)
    }
  }

  return(
    <>
      {!addRecipientVisible &&
        <Select
          id="select_therapist"
          options={selectOptions()}
          onChange={selectAction}
          styles={customStyles}
          isClearable={true}
          value={selectedValue}
        />
      }
    </>
  )
}

TherapistList.propTypes = {
  allowCustom: PropTypes.bool
}

export default TherapistList