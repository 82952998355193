import React from 'react'

import Search from '../lib/search'

const IntercomSearch = ({editUrl}) => {
  return (
    <Search
      placeholder="Enter email to search for"
      baseUrl="/intercom"
      editUrl={editUrl}
    />
  )
}

export default IntercomSearch
