import React from 'react'
import TherapistForm from './form'

const NewTherapist = () => {
  return (
    <div className="card mb-3">
      <div className="card-header">Add a new therapist</div>
      <TherapistForm therapist={{}} />
    </div>
  )
}

export default NewTherapist
