import React from 'react'
import PropTypes from 'prop-types'
import { StoreProvider } from 'easy-peasy'
import store from './store'
import styles from './css'
import ReplyMessage from './components/reply-message'
import Messages from './components/messages'

const CustomReply = props => {
  const from_admin = props.conversation.admins.find(admin => admin.id === props.admin_id)

  const initialState = {
    conversation: props.conversation,
    messages: props.messages,
    admin_id: props.admin_id,
    from_email: props.admin_id,
    recipients: props.conversation.recipients,
    copy_type: props.conversation.copy_type,
    subject: props.conversation.subject,
    reply: props.conversation.message,
    subject: props.conversation.subject,
    open: props.open
  }

  return (
    <StoreProvider store={store(initialState)}>
      <ReplyMessage />
      <Messages />
    </StoreProvider>
  )
}

CustomReply.propTypes = {
  conversation: PropTypes.object,
  messages: PropTypes.array,
  admin_id: PropTypes.string,
  open: PropTypes.array
}

export default CustomReply

