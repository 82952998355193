import React from 'react'
import PropTypes from 'prop-types'
import { useStoreState, useStoreActions } from 'easy-peasy'
import { ReactTrixRTEInput } from 'react-trix-rte'
import styles from '../css/reply-text'

const ReplyText = (props) => {
  const placeholder = props.placeholder || 'type your reply here'
  const reply = useStoreState(state => state.reply)
  const setReply = useStoreActions(actions => actions.setReply)

  const updateReply = (event, newValue) => {
    setReply(newValue)
  }

  return (
    <ReactTrixRTEInput
      className="reply-text"
      autoFocus={true}
      defaultValue={reply}
      placeholder={placeholder}
      isRailsDirectUpload={true}
      onChange={updateReply}
    />
  )
}

ReplyText.propTypes = {
  placeholder: PropTypes.string
}

export default ReplyText
