import conversation from './conversation'
import { action, computed } from 'easy-peasy'

const model = {
  conversation: conversation,
  copy_type: 'bcc',
  recipients: [],
  reply: '',
  from_email: '',
  admin_id: '',
  addRecipientVisible: false,
  subject: '',
  open: [],
  insertedMessages: [],
  setSubject: action((state, subject) => {
    state.subject = subject
  }),
  userMessages: computed((state) => {
    return state.messages.filter(message => message.sent_by === 'user' || message.sent_by === 'lead')
  }),
  submitActive: computed((state) => {
    return (
      (state.copy_type === 'bcc' && state.reply) ||
      (state.copy_type === 'fwd' && state.reply && state.subject && state.recipients.length > 0)
    )
  }),
  insertMessages: action((state, messages) => {
    state.insertedMessages = [...messages]
  }),
  setCopyType: action((state, copy_type) => {
    state.copy_type = copy_type
  }),
  addRecipient: action((state, recipient) => {
    console.log('adding recipient', recipient)
    state.recipients.push(recipient)
  }),
  removeRecipient: action((state, recipientPos) => {
    state.recipients.splice(recipientPos, 1)
  }),
  setReply: action((state, reply) => {
    state.reply = reply
  }),
  updateFromEmail: action((state, email) => {
    state.from_email = email
  }),
  setAddRecipientVisible: action((state, value) => {
    state.addRecipientVisible = value
  })
}

export default model
