import React from 'react'
import Search from './components/search'
import { StoreProvider } from 'easy-peasy'
import store from './store'

const Sar = () => {
  return (
    <StoreProvider store={store()}>
      <Search />
    </StoreProvider>
  )
}

export default Sar
