import React, { useState } from 'react'
import { useStoreState, useStoreActions } from 'easy-peasy'
import TimeAgo from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/en'
import renderHtml from 'html-react-parser'

// Add locale-specific relative date/time formatting rules.
TimeAgo.addLocale(en)
const timeAgo = new TimeAgo('en-GB')

const MessageFrom = (props) => {
  const message = props.message
  return (
    <>
      <span className="name">{message.author}</span>
      <span className="time">{timeAgo.format(new Date(message.sent_at))}</span>
    </>
  )
}

const Message = (props) => {
  const message = props.message
  const messageClass = `message ${message.sent_by} ${message.part_type}`

  if (message.part_type === 'assignment' || message.part_type === 'message_assignment' ||
      message.part_type === 'open' || message.part_type === 'closed' ) {

    let actionText = 'updated'
    switch (message.part_type) {
      case 'assignment':
      case 'message_assignment':
        actionText = `assigned to <span class="name">${message.assigned_to}</span>`
        break
      case 'open':
        actionText = 're-opened'
        break
      case 'closed':
        actionText = 'closed'
        break
    }
    return (
      <>
        {message.body &&
          <div className={'message comment' + (message.subject ? ' has-subject' : '')}>{renderHtml(message.body)}</div>
        }
        <div className={messageClass}>
          Conversation {renderHtml(actionText)} by
          <MessageFrom
            message={message}
          />
        </div>
      </>
    )
  } else {
    return (
      <>
      {message.subject &&
        <div className="subject">{message.subject}</div>
      }
      {message.body &&
        <div className={messageClass + (message.subject ? ' has-subject' : '')}>{renderHtml(message.body)}</div>
      }
      <div className="author">
        <MessageFrom
          message={message}
        />
      </div>
      </>
    )
  }
}

const Messages = props => {
  const conversation = useStoreState(state => state.conversation)
  const messages = useStoreState(state => state.messages)
  return (
    <div className="card mt-3 mb-3">
      <div className="card-header">
        Conversation with {conversation.people} - last updated {timeAgo.format(new Date(conversation.last_updated_at))}
      </div>
      <div className="intercom-messages">
        {messages.map((message, key) =>
          <div
            key={key}
            className={`message-wrapper ${message.sent_by} ${message.part_type} ${message.body && 'has_body'}` }
          >
            <Message
              message={message}
            />
          </div>
        )}
      </div>
    </div>
  )
}

export default Messages