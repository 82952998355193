import { faCircleNotch } from '@fortawesome/pro-duotone-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { Suspense } from 'react'
import TherapistList from './list'
import useModel from './model'
import NewTherapist from './new'

const Therapists = () => {
  const model = useModel()
  return (
    <div className="p-3">
      <div>
        {model.creatingTherapist ? (
          <NewTherapist />
        ) : (
          <button
            className="btn btn-sm btn-outline-primary"
            onClick={() => (model.creatingTherapist = true)}
          >
            New...
          </button>
        )}
      </div>
      <Suspense fallback={<FontAwesomeIcon icon={faCircleNotch} spin />}>
        <TherapistList />
      </Suspense>
    </div>
  )
}

export default Therapists
