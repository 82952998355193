import { createStore, action, thunk } from 'easy-peasy'

const store = (initialState) => {
  return createStore(
    {
      user: {},
      updateUser: action((state, newUser) => {
        state.user = {...state.user, ...newUser}
      })
    },
    {
      initialState: initialState,
      name: 'SAR store' }
  )
}

export default store
