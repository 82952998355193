import { createModel, query } from 'ibiza'
import { sortBy } from 'lodash'

const useModel = createModel('therapists', (_, props) => ({
  // hack to allow invalidation of the query model
  version: 1,
  refetch() {
    this.version += 1
  },
  editingTherapistId: null,
  creatingTherapist: false,
  therapists: query(function () {
    return `/therapists?${this.version}`
  }),
  saving: false,

  get sortedTherapists() {
    if (!this.therapists) return []

    return sortBy(this.therapists, ['first_name', 'name'])
  },
  ...props,
}))

export default useModel
