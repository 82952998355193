import React, { useState } from 'react'
import isEqual from 'fast-deep-equal'
import Select from '../lib/select'
import useModel from './model'
import htFetch from '../lib/fetch'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleNotch } from '@fortawesome/pro-duotone-svg-icons'

const TherapistForm = ({ therapist }) => {
  const [draft, setDraft] = useState(therapist)
  const model = useModel()

  const updateDraft = (updates) => {
    setDraft({ ...draft, ...updates })
  }

  const isDirty = !isEqual(draft, therapist)

  const canSave =
    draft.first_name &&
    draft.last_name &&
    draft.email &&
    draft.filemaker_id &&
    !model.saving

  const save = () => {
    model.saving = true
    htFetch(`/therapists${draft.id ? `/${draft.id}` : ''}`, {
      method: draft.id ? 'PATCH' : 'POST',
      body: JSON.stringify({
        therapist: draft,
      }),
    }).then((response) => {
      model.saving = false
      if (response.ok) {
        draft.id
          ? (model.editingTherapistId = null)
          : (model.creatingTherapist = null)
        model.refetch()
      } else {
        response
          .json()
          .then((body) => alert(`An error occurred: ${body.errors}`))
      }
    })
  }

  return (
    <div className="p-3">
      <div className="mb-3 d-flex">
        <div className="me-3">
          <Select
            title="Title"
            value={draft.title}
            options={[{ label: 'Dr', value: 'Dr' }]}
            placeholder="no title"
            onChange={(newTitle) => updateDraft({ title: newTitle })}
          />
        </div>
        <div className="flex-grow-1 me-3">
          <label for="first_name" className="form-label">
            First name
          </label>
          <input
            type="text"
            defaultValue={draft.first_name}
            className="form-control"
            id="first_name"
            placeholder="first name"
            onChange={(e) => updateDraft({ first_name: e.target.value })}
          />
        </div>
        <div className="flex-grow-1">
          <label for="Last_name" className="form-label">
            Last name
          </label>
          <input
            type="text"
            className="form-control"
            defaultValue={draft.last_name}
            id="last_name"
            placeholder="last name"
            onChange={(e) => updateDraft({ last_name: e.target.value })}
          />
        </div>
      </div>
      <div className="mb-3">
        <label for="email" className="form-label">
          Email
        </label>
        <input
          type="email"
          defaultValue={draft.email}
          className="form-control"
          id="email"
          placeholder="name@example.com"
          onChange={(e) => updateDraft({ email: e.target.value })}
        />
      </div>
      <div className="mb-3">
        <label for="filemaker_id" className="form-label">
          Filemaker ID
        </label>
        <input
          type="text"
          className="form-control"
          defaultValue={draft.filemaker_id}
          id="filemaker_id"
          placeholder="filemaker id"
          onChange={(e) => updateDraft({ filemaker_id: e.target.value })}
        />
      </div>
      <div className="d-flex align-items-center">
        <div
          className="btn btn-outline-secondary btn-sm"
          onClick={() => {
            draft.id
              ? (model.editingTherapistId = null)
              : (model.creatingTherapist = false)
          }}
        >
          Cancel
        </div>
        <div
          className={`ms-auto btn btn-success btn-sm ${
            isDirty && canSave ? '' : 'disabled'
          }`}
          onClick={save}
        >
          {model.saving ? (
            <span>
              saving <FontAwesomeIcon icon={faCircleNotch} spin />
            </span>
          ) : (
            <span>Save</span>
          )}
        </div>
      </div>
    </div>
  )
}

export default TherapistForm
