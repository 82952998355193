import React from 'react'
import { StoreProvider, useStoreState, useStoreActions } from 'easy-peasy'
import store from './store'
import Search from './search'

const SearchWrapper = ({baseUrl, placeholder, delay, editUrl}) => {
  return (
    <StoreProvider store={store({ baseUrl: baseUrl })}>
      <Search placeholder={placeholder} delay={delay} editUrl={editUrl} />
    </StoreProvider>
  )
}

export default SearchWrapper
