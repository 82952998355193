import React from 'react'
import { StoreProvider } from 'easy-peasy'
import PropTypes from 'prop-types'
import store from './store'
import ConversationtList from './components/conversation-list'

const ExportDetail = (props) => {
  const initialState = {
    conversations: props.conversations,
    loading: props.loading,
    subject: props.subject
  }

  return (
    <StoreProvider store={store(initialState)}>
      <ConversationtList />
    </StoreProvider>
  )

}

ExportDetail.propTypes = {
  conversations: PropTypes.array,
  loading: PropTypes.bool,
  subject: PropTypes.object
}

export default ExportDetail