import { createStore, action, computed } from 'easy-peasy'

const store = (initialState) => {
  return createStore(
    {
      exports: [],
      baseUrl: '',
      loading: computed(state => state.exports.some(ex => ex.loading_status === true)),
      replaceExports: action((state, newExports) => {
        state.exports = [...newExports]
      })
    },
    { initialState: initialState }
  )
}

export default store
