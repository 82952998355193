import React from 'react'

const Select = ({
  title,
  options,
  value,
  onChange,
  className,
  placeholder,
  returnObject = false,
  ...additionalSelectProps
}) => {
  return (
    <div>
      {title && <label className="form-label">{title}</label>}
      <select
        className={className || 'form-select'}
        value={value || -1}
        onChange={(e) => {
          onChange(
            e.target.value === -1
              ? null
              : returnObject
              ? options.find(
                  (o) => parseInt(o.value) === parseInt(e.target.value)
                )
              : e.target.value
          )
        }}
        {...additionalSelectProps}
      >
        {placeholder && (
          <option key={999999} value={-1}>
            {placeholder}
          </option>
        )}
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </div>
  )
}

export default Select
