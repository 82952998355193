import { createStore, StoreProvider } from 'easy-peasy'
import model from './model'

const store = (initialState) => {
  return createStore(
    model,
    { initialState: initialState }
  )
}

export default store
