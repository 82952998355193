import { createStore, StoreProvider, action } from 'easy-peasy'

const store = (initialState) => {
  return createStore(
    {
      reply: {},
      updateReply: action((state, reply) => {
        state.reply = reply
      })
    },
    { initialState: initialState }
  )
}

export default store
