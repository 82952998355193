
import React from 'react'
import PropTypes from 'prop-types'
import { StoreProvider } from 'easy-peasy'
import store from './store'
import styles from './css'

import Container from './components/container'

const ReplyStatus = (props) => {
  const initialState = {
    reply: props.reply
  }

  return (
    <StoreProvider store={store(initialState)}>
    	<Container />
    </StoreProvider>
  )

}

ReplyStatus.propTypes = {
  reply: PropTypes.object
}

export default ReplyStatus